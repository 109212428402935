<template>
  <div :class="computedClasses">
    <input
      v-bind="otherAttrs"
      :id="computedId"
      v-model="internalModel"
      :class="inputClasses"
      type="checkbox"
    >
    <label
      class="zform-checkbox-label"
      :for="computedId"
    ><slot /></label>
  </div>
</template>

<script setup lang="ts">
import type { FormCheckboxSize, FormCheckboxVariant, FormValue } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  id?: string
  toggle?: boolean
  inline?: boolean
  bordered?: boolean
  size?: FormCheckboxSize
  variant?: FormCheckboxVariant
}>(), {
  size: 'md',
  variant: 'highlight',
})
const internalModel = defineModel<FormValue>()

defineOptions({
  inheritAttrs: false,
})
const { class: _, ...otherAttrs } = useAttrs()

const uuid = useId().replace('_', '-') // Temporary fix for pre 3.5+ usage of `useId()`
const computedId = computed(() => props.id || uuid)

const inputClasses = computed(() => {
  const type = props.toggle ? 'toggle' : 'box'
  return [
    'zform-checkbox-input',
    `zform-checkbox-${type}`,
    `zform-checkbox-${type}-${props.size}`,
    `zform-checkbox-${type}-${props.variant}`,
  ]
})

const computedClasses = computed(() => [
  'zform-checkbox',
  {
    'zform-checkbox-inline': props.inline,
    'zform-checkbox-bordered': props.bordered,
  },
  useAttrs()?.class,
])
</script>
